var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, e) {
  exports = e();
}(window, function () {
  return function (t) {
    var e = {};

    function r(n) {
      if (e[n]) return e[n].exports;
      var o = e[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return t[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
    }

    return r.m = t, r.c = e, r.d = function (t, e, n) {
      r.o(t, e) || Object.defineProperty(t, e, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, r.t = function (t, e) {
      if (1 & e && (t = r(t)), 8 & e) return t;
      if (4 & e && "object" == typeof t && t && t.__esModule) return t;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: t
      }), 2 & e && "string" != typeof t) for (var o in t) r.d(n, o, function (e) {
        return t[e];
      }.bind(null, o));
      return n;
    }, r.n = function (t) {
      var e = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return r.d(e, "a", e), e;
    }, r.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, r.p = "", r(r.s = 17);
  }([function (t, e, r) {
    "use strict";

    var n = r(7),
        o = "function" == typeof Symbol && "symbol" == typeof Symbol("foo"),
        i = Object.prototype.toString,
        s = Array.prototype.concat,
        a = Object.defineProperty,
        u = r(25)(),
        p = a && u,
        c = function (t, e, r, n) {
      var o;
      (!(e in t) || "function" == typeof (o = n) && "[object Function]" === i.call(o) && n()) && (p ? a(t, e, {
        configurable: !0,
        enumerable: !1,
        value: r,
        writable: !0
      }) : t[e] = r);
    },
        l = function (t, e) {
      var r = arguments.length > 2 ? arguments[2] : {},
          i = n(e);
      o && (i = s.call(i, Object.getOwnPropertySymbols(e)));

      for (var a = 0; a < i.length; a += 1) c(t, i[a], e[i[a]], r[i[a]]);
    };

    l.supportsDescriptors = !!p, t.exports = l;
  }, function (t, e, r) {
    "use strict";

    var n = r(9);

    t.exports = function () {
      return n() && !!Symbol.toStringTag;
    };
  }, function (t, e, r) {
    "use strict";

    var n = SyntaxError,
        o = Function,
        i = TypeError,
        s = function (t) {
      try {
        return o("\"use strict\"; return (" + t + ").constructor;")();
      } catch (t) {}
    },
        a = Object.getOwnPropertyDescriptor;

    if (a) try {
      a({}, "");
    } catch (t) {
      a = null;
    }

    var u = function () {
      throw new i();
    },
        p = a ? function () {
      try {
        return u;
      } catch (t) {
        try {
          return a(arguments, "callee").get;
        } catch (t) {
          return u;
        }
      }
    }() : u,
        c = r(21)(),
        l = Object.getPrototypeOf || function (t) {
      return t.__proto__;
    },
        f = {},
        h = "undefined" == typeof Uint8Array ? void 0 : l(Uint8Array),
        y = {
      "%AggregateError%": "undefined" == typeof AggregateError ? void 0 : AggregateError,
      "%Array%": Array,
      "%ArrayBuffer%": "undefined" == typeof ArrayBuffer ? void 0 : ArrayBuffer,
      "%ArrayIteratorPrototype%": c ? l([][Symbol.iterator]()) : void 0,
      "%AsyncFromSyncIteratorPrototype%": void 0,
      "%AsyncFunction%": f,
      "%AsyncGenerator%": f,
      "%AsyncGeneratorFunction%": f,
      "%AsyncIteratorPrototype%": f,
      "%Atomics%": "undefined" == typeof Atomics ? void 0 : Atomics,
      "%BigInt%": "undefined" == typeof BigInt ? void 0 : BigInt,
      "%Boolean%": Boolean,
      "%DataView%": "undefined" == typeof DataView ? void 0 : DataView,
      "%Date%": Date,
      "%decodeURI%": decodeURI,
      "%decodeURIComponent%": decodeURIComponent,
      "%encodeURI%": encodeURI,
      "%encodeURIComponent%": encodeURIComponent,
      "%Error%": Error,
      "%eval%": eval,
      "%EvalError%": EvalError,
      "%Float32Array%": "undefined" == typeof Float32Array ? void 0 : Float32Array,
      "%Float64Array%": "undefined" == typeof Float64Array ? void 0 : Float64Array,
      "%FinalizationRegistry%": "undefined" == typeof FinalizationRegistry ? void 0 : FinalizationRegistry,
      "%Function%": o,
      "%GeneratorFunction%": f,
      "%Int8Array%": "undefined" == typeof Int8Array ? void 0 : Int8Array,
      "%Int16Array%": "undefined" == typeof Int16Array ? void 0 : Int16Array,
      "%Int32Array%": "undefined" == typeof Int32Array ? void 0 : Int32Array,
      "%isFinite%": isFinite,
      "%isNaN%": isNaN,
      "%IteratorPrototype%": c ? l(l([][Symbol.iterator]())) : void 0,
      "%JSON%": "object" == typeof JSON ? JSON : void 0,
      "%Map%": "undefined" == typeof Map ? void 0 : Map,
      "%MapIteratorPrototype%": "undefined" != typeof Map && c ? l(new Map()[Symbol.iterator]()) : void 0,
      "%Math%": Math,
      "%Number%": Number,
      "%Object%": Object,
      "%parseFloat%": parseFloat,
      "%parseInt%": parseInt,
      "%Promise%": "undefined" == typeof Promise ? void 0 : Promise,
      "%Proxy%": "undefined" == typeof Proxy ? void 0 : Proxy,
      "%RangeError%": RangeError,
      "%ReferenceError%": ReferenceError,
      "%Reflect%": "undefined" == typeof Reflect ? void 0 : Reflect,
      "%RegExp%": RegExp,
      "%Set%": "undefined" == typeof Set ? void 0 : Set,
      "%SetIteratorPrototype%": "undefined" != typeof Set && c ? l(new Set()[Symbol.iterator]()) : void 0,
      "%SharedArrayBuffer%": "undefined" == typeof SharedArrayBuffer ? void 0 : SharedArrayBuffer,
      "%String%": String,
      "%StringIteratorPrototype%": c ? l(""[Symbol.iterator]()) : void 0,
      "%Symbol%": c ? Symbol : void 0,
      "%SyntaxError%": n,
      "%ThrowTypeError%": p,
      "%TypedArray%": h,
      "%TypeError%": i,
      "%Uint8Array%": "undefined" == typeof Uint8Array ? void 0 : Uint8Array,
      "%Uint8ClampedArray%": "undefined" == typeof Uint8ClampedArray ? void 0 : Uint8ClampedArray,
      "%Uint16Array%": "undefined" == typeof Uint16Array ? void 0 : Uint16Array,
      "%Uint32Array%": "undefined" == typeof Uint32Array ? void 0 : Uint32Array,
      "%URIError%": URIError,
      "%WeakMap%": "undefined" == typeof WeakMap ? void 0 : WeakMap,
      "%WeakRef%": "undefined" == typeof WeakRef ? void 0 : WeakRef,
      "%WeakSet%": "undefined" == typeof WeakSet ? void 0 : WeakSet
    },
        g = {
      "%ArrayBufferPrototype%": ["ArrayBuffer", "prototype"],
      "%ArrayPrototype%": ["Array", "prototype"],
      "%ArrayProto_entries%": ["Array", "prototype", "entries"],
      "%ArrayProto_forEach%": ["Array", "prototype", "forEach"],
      "%ArrayProto_keys%": ["Array", "prototype", "keys"],
      "%ArrayProto_values%": ["Array", "prototype", "values"],
      "%AsyncFunctionPrototype%": ["AsyncFunction", "prototype"],
      "%AsyncGenerator%": ["AsyncGeneratorFunction", "prototype"],
      "%AsyncGeneratorPrototype%": ["AsyncGeneratorFunction", "prototype", "prototype"],
      "%BooleanPrototype%": ["Boolean", "prototype"],
      "%DataViewPrototype%": ["DataView", "prototype"],
      "%DatePrototype%": ["Date", "prototype"],
      "%ErrorPrototype%": ["Error", "prototype"],
      "%EvalErrorPrototype%": ["EvalError", "prototype"],
      "%Float32ArrayPrototype%": ["Float32Array", "prototype"],
      "%Float64ArrayPrototype%": ["Float64Array", "prototype"],
      "%FunctionPrototype%": ["Function", "prototype"],
      "%Generator%": ["GeneratorFunction", "prototype"],
      "%GeneratorPrototype%": ["GeneratorFunction", "prototype", "prototype"],
      "%Int8ArrayPrototype%": ["Int8Array", "prototype"],
      "%Int16ArrayPrototype%": ["Int16Array", "prototype"],
      "%Int32ArrayPrototype%": ["Int32Array", "prototype"],
      "%JSONParse%": ["JSON", "parse"],
      "%JSONStringify%": ["JSON", "stringify"],
      "%MapPrototype%": ["Map", "prototype"],
      "%NumberPrototype%": ["Number", "prototype"],
      "%ObjectPrototype%": ["Object", "prototype"],
      "%ObjProto_toString%": ["Object", "prototype", "toString"],
      "%ObjProto_valueOf%": ["Object", "prototype", "valueOf"],
      "%PromisePrototype%": ["Promise", "prototype"],
      "%PromiseProto_then%": ["Promise", "prototype", "then"],
      "%Promise_all%": ["Promise", "all"],
      "%Promise_reject%": ["Promise", "reject"],
      "%Promise_resolve%": ["Promise", "resolve"],
      "%RangeErrorPrototype%": ["RangeError", "prototype"],
      "%ReferenceErrorPrototype%": ["ReferenceError", "prototype"],
      "%RegExpPrototype%": ["RegExp", "prototype"],
      "%SetPrototype%": ["Set", "prototype"],
      "%SharedArrayBufferPrototype%": ["SharedArrayBuffer", "prototype"],
      "%StringPrototype%": ["String", "prototype"],
      "%SymbolPrototype%": ["Symbol", "prototype"],
      "%SyntaxErrorPrototype%": ["SyntaxError", "prototype"],
      "%TypedArrayPrototype%": ["TypedArray", "prototype"],
      "%TypeErrorPrototype%": ["TypeError", "prototype"],
      "%Uint8ArrayPrototype%": ["Uint8Array", "prototype"],
      "%Uint8ClampedArrayPrototype%": ["Uint8ClampedArray", "prototype"],
      "%Uint16ArrayPrototype%": ["Uint16Array", "prototype"],
      "%Uint32ArrayPrototype%": ["Uint32Array", "prototype"],
      "%URIErrorPrototype%": ["URIError", "prototype"],
      "%WeakMapPrototype%": ["WeakMap", "prototype"],
      "%WeakSetPrototype%": ["WeakSet", "prototype"]
    },
        d = r(3),
        b = r(23),
        m = d.call(Function.call, Array.prototype.concat),
        v = d.call(Function.apply, Array.prototype.splice),
        x = d.call(Function.call, String.prototype.replace),
        w = d.call(Function.call, String.prototype.slice),
        j = d.call(Function.call, RegExp.prototype.exec),
        O = /[^%.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|%$))/g,
        A = /\\(\\)?/g,
        P = function (t) {
      var e = w(t, 0, 1),
          r = w(t, -1);
      if ("%" === e && "%" !== r) throw new n("invalid intrinsic syntax, expected closing `%`");
      if ("%" === r && "%" !== e) throw new n("invalid intrinsic syntax, expected opening `%`");
      var o = [];
      return x(t, O, function (t, e, r, n) {
        o[o.length] = r ? x(n, A, "$1") : e || t;
      }), o;
    },
        S = function (t, e) {
      var r,
          o = t;

      if (b(g, o) && (o = "%" + (r = g[o])[0] + "%"), b(y, o)) {
        var a = y[o];
        if (a === f && (a = function t(e) {
          var r;
          if ("%AsyncFunction%" === e) r = s("async function () {}");else if ("%GeneratorFunction%" === e) r = s("function* () {}");else if ("%AsyncGeneratorFunction%" === e) r = s("async function* () {}");else if ("%AsyncGenerator%" === e) {
            var n = t("%AsyncGeneratorFunction%");
            n && (r = n.prototype);
          } else if ("%AsyncIteratorPrototype%" === e) {
            var o = t("%AsyncGenerator%");
            o && (r = l(o.prototype));
          }
          return y[e] = r, r;
        }(o)), void 0 === a && !e) throw new i("intrinsic " + t + " exists, but is not available. Please file an issue!");
        return {
          alias: r,
          name: o,
          value: a
        };
      }

      throw new n("intrinsic " + t + " does not exist!");
    };

    t.exports = function (t, e) {
      if ("string" != typeof t || 0 === t.length) throw new i("intrinsic name must be a non-empty string");
      if (arguments.length > 1 && "boolean" != typeof e) throw new i("\"allowMissing\" argument must be a boolean");
      if (null === j(/^%?[^%]*%?$/g, t)) throw new n("`%` may not be present anywhere but at the beginning and end of the intrinsic name");
      var r = P(t),
          o = r.length > 0 ? r[0] : "",
          s = S("%" + o + "%", e),
          u = s.name,
          p = s.value,
          c = !1,
          l = s.alias;
      l && (o = l[0], v(r, m([0, 1], l)));

      for (var f = 1, h = !0; f < r.length; f += 1) {
        var g = r[f],
            d = w(g, 0, 1),
            x = w(g, -1);
        if (("\"" === d || "'" === d || "`" === d || "\"" === x || "'" === x || "`" === x) && d !== x) throw new n("property names with quotes must have matching quotes");
        if ("constructor" !== g && h || (c = !0), b(y, u = "%" + (o += "." + g) + "%")) p = y[u];else if (null != p) {
          if (!(g in p)) {
            if (!e) throw new i("base intrinsic for " + t + " exists, but the property is not available.");
            return;
          }

          if (a && f + 1 >= r.length) {
            var O = a(p, g);
            p = (h = !!O) && "get" in O && !("originalValue" in O.get) ? O.get : p[g];
          } else h = b(p, g), p = p[g];

          h && !c && (y[u] = p);
        }
      }

      return p;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(22);
    t.exports = Function.prototype.bind || n;
  }, function (t, e, r) {
    "use strict";

    var n = r(3),
        o = r(2),
        i = o("%Function.prototype.apply%"),
        s = o("%Function.prototype.call%"),
        a = o("%Reflect.apply%", !0) || n.call(s, i),
        u = o("%Object.getOwnPropertyDescriptor%", !0),
        p = o("%Object.defineProperty%", !0),
        c = o("%Math.max%");
    if (p) try {
      p({}, "a", {
        value: 1
      });
    } catch (t) {
      p = null;
    }

    t.exports = function (t) {
      var e = a(n, s, arguments);

      if (u && p) {
        var r = u(e, "length");
        r.configurable && p(e, "length", {
          value: 1 + c(0, t.length - (arguments.length - 1))
        });
      }

      return e;
    };

    var l = function () {
      return a(n, i, arguments);
    };

    p ? p(t.exports, "apply", {
      value: l
    }) : t.exports.apply = l;
  }, function (t, e, r) {
    var n = r(18),
        o = r(6),
        i = r(15),
        s = r(32),
        a = String.fromCharCode(0),
        u = function (t) {
      Array.isArray(t) ? (this || _global).ops = t : null != t && Array.isArray(t.ops) ? (this || _global).ops = t.ops : (this || _global).ops = [];
    };

    u.prototype.insert = function (t, e) {
      var r = {};
      return 0 === t.length ? this || _global : (r.insert = t, null != e && "object" == typeof e && Object.keys(e).length > 0 && (r.attributes = e), this.push(r));
    }, u.prototype.delete = function (t) {
      return t <= 0 ? this || _global : this.push({
        delete: t
      });
    }, u.prototype.retain = function (t, e) {
      if (t <= 0) return this || _global;
      var r = {
        retain: t
      };
      return null != e && "object" == typeof e && Object.keys(e).length > 0 && (r.attributes = e), this.push(r);
    }, u.prototype.push = function (t) {
      var e = (this || _global).ops.length,
          r = (this || _global).ops[e - 1];

      if (t = i(!0, {}, t), "object" == typeof r) {
        if ("number" == typeof t.delete && "number" == typeof r.delete) return (this || _global).ops[e - 1] = {
          delete: r.delete + t.delete
        }, this || _global;
        if ("number" == typeof r.delete && null != t.insert && (e -= 1, "object" != typeof (r = (this || _global).ops[e - 1]))) return (this || _global).ops.unshift(t), this || _global;

        if (o(t.attributes, r.attributes)) {
          if ("string" == typeof t.insert && "string" == typeof r.insert) return (this || _global).ops[e - 1] = {
            insert: r.insert + t.insert
          }, "object" == typeof t.attributes && ((this || _global).ops[e - 1].attributes = t.attributes), this || _global;
          if ("number" == typeof t.retain && "number" == typeof r.retain) return (this || _global).ops[e - 1] = {
            retain: r.retain + t.retain
          }, "object" == typeof t.attributes && ((this || _global).ops[e - 1].attributes = t.attributes), this || _global;
        }
      }

      return e === (this || _global).ops.length ? (this || _global).ops.push(t) : (this || _global).ops.splice(e, 0, t), this || _global;
    }, u.prototype.chop = function () {
      var t = (this || _global).ops[(this || _global).ops.length - 1];
      return t && t.retain && !t.attributes && (this || _global).ops.pop(), this || _global;
    }, u.prototype.filter = function (t) {
      return (this || _global).ops.filter(t);
    }, u.prototype.forEach = function (t) {
      (this || _global).ops.forEach(t);
    }, u.prototype.map = function (t) {
      return (this || _global).ops.map(t);
    }, u.prototype.partition = function (t) {
      var e = [],
          r = [];
      return this.forEach(function (n) {
        (t(n) ? e : r).push(n);
      }), [e, r];
    }, u.prototype.reduce = function (t, e) {
      return (this || _global).ops.reduce(t, e);
    }, u.prototype.changeLength = function () {
      return this.reduce(function (t, e) {
        return e.insert ? t + s.length(e) : e.delete ? t - e.delete : t;
      }, 0);
    }, u.prototype.length = function () {
      return this.reduce(function (t, e) {
        return t + s.length(e);
      }, 0);
    }, u.prototype.slice = function (t, e) {
      t = t || 0, "number" != typeof e && (e = 1 / 0);

      for (var r = [], n = s.iterator((this || _global).ops), o = 0; o < e && n.hasNext();) {
        var i;
        o < t ? i = n.next(t - o) : (i = n.next(e - o), r.push(i)), o += s.length(i);
      }

      return new u(r);
    }, u.prototype.compose = function (t) {
      var e = s.iterator((this || _global).ops),
          r = s.iterator(t.ops),
          n = [],
          i = r.peek();

      if (null != i && "number" == typeof i.retain && null == i.attributes) {
        for (var a = i.retain; "insert" === e.peekType() && e.peekLength() <= a;) a -= e.peekLength(), n.push(e.next());

        i.retain - a > 0 && r.next(i.retain - a);
      }

      for (var p = new u(n); e.hasNext() || r.hasNext();) if ("insert" === r.peekType()) p.push(r.next());else if ("delete" === e.peekType()) p.push(e.next());else {
        var c = Math.min(e.peekLength(), r.peekLength()),
            l = e.next(c),
            f = r.next(c);

        if ("number" == typeof f.retain) {
          var h = {};
          "number" == typeof l.retain ? h.retain = c : h.insert = l.insert;
          var y = s.attributes.compose(l.attributes, f.attributes, "number" == typeof l.retain);

          if (y && (h.attributes = y), p.push(h), !r.hasNext() && o(p.ops[p.ops.length - 1], h)) {
            var g = new u(e.rest());
            return p.concat(g).chop();
          }
        } else "number" == typeof f.delete && "number" == typeof l.retain && p.push(f);
      }

      return p.chop();
    }, u.prototype.concat = function (t) {
      var e = new u((this || _global).ops.slice());
      return t.ops.length > 0 && (e.push(t.ops[0]), e.ops = e.ops.concat(t.ops.slice(1))), e;
    }, u.prototype.diff = function (t, e) {
      if ((this || _global).ops === t.ops) return new u();
      var r = [this || _global, t].map(function (e) {
        return e.map(function (r) {
          if (null != r.insert) return "string" == typeof r.insert ? r.insert : a;
          throw new Error("diff() called " + (e === t ? "on" : "with") + " non-document");
        }).join("");
      }),
          i = new u(),
          p = n(r[0], r[1], e),
          c = s.iterator((this || _global).ops),
          l = s.iterator(t.ops);
      return p.forEach(function (t) {
        for (var e = t[1].length; e > 0;) {
          var r = 0;

          switch (t[0]) {
            case n.INSERT:
              r = Math.min(l.peekLength(), e), i.push(l.next(r));
              break;

            case n.DELETE:
              r = Math.min(e, c.peekLength()), c.next(r), i.delete(r);
              break;

            case n.EQUAL:
              r = Math.min(c.peekLength(), l.peekLength(), e);
              var a = c.next(r),
                  u = l.next(r);
              o(a.insert, u.insert) ? i.retain(r, s.attributes.diff(a.attributes, u.attributes)) : i.push(u).delete(r);
          }

          e -= r;
        }
      }), i.chop();
    }, u.prototype.eachLine = function (t, e) {
      e = e || "\n";

      for (var r = s.iterator((this || _global).ops), n = new u(), o = 0; r.hasNext();) {
        if ("insert" !== r.peekType()) return;
        var i = r.peek(),
            a = s.length(i) - r.peekLength(),
            p = "string" == typeof i.insert ? i.insert.indexOf(e, a) - a : -1;
        if (p < 0) n.push(r.next());else if (p > 0) n.push(r.next(p));else {
          if (!1 === t(n, r.next(1).attributes || {}, o)) return;
          o += 1, n = new u();
        }
      }

      n.length() > 0 && t(n, {}, o);
    }, u.prototype.transform = function (t, e) {
      if (e = !!e, "number" == typeof t) return this.transformPosition(t, e);

      for (var r = s.iterator((this || _global).ops), n = s.iterator(t.ops), o = new u(); r.hasNext() || n.hasNext();) if ("insert" !== r.peekType() || !e && "insert" === n.peekType()) {
        if ("insert" === n.peekType()) o.push(n.next());else {
          var i = Math.min(r.peekLength(), n.peekLength()),
              a = r.next(i),
              p = n.next(i);
          if (a.delete) continue;
          p.delete ? o.push(p) : o.retain(i, s.attributes.transform(a.attributes, p.attributes, e));
        }
      } else o.retain(s.length(r.next()));

      return o.chop();
    }, u.prototype.transformPosition = function (t, e) {
      e = !!e;

      for (var r = s.iterator((this || _global).ops), n = 0; r.hasNext() && n <= t;) {
        var o = r.peekLength(),
            i = r.peekType();
        r.next(), "delete" !== i ? ("insert" === i && (n < t || !e) && (t += o), n += o) : t -= Math.min(o, t - n);
      }

      return t;
    }, t.exports = u;
  }, function (t, e, r) {
    var n = r(7),
        o = r(20),
        i = r(24),
        s = r(27),
        a = r(28),
        u = r(31),
        p = Date.prototype.getTime;

    function c(t, e, r) {
      var h = r || {};
      return !!(h.strict ? i(t, e) : t === e) || (!t || !e || "object" != typeof t && "object" != typeof e ? h.strict ? i(t, e) : t == e : function (t, e, r) {
        var i, h;
        if (typeof t != typeof e) return !1;
        if (l(t) || l(e)) return !1;
        if (t.prototype !== e.prototype) return !1;
        if (o(t) !== o(e)) return !1;
        var y = s(t),
            g = s(e);
        if (y !== g) return !1;
        if (y || g) return t.source === e.source && a(t) === a(e);
        if (u(t) && u(e)) return p.call(t) === p.call(e);
        var d = f(t),
            b = f(e);
        if (d !== b) return !1;

        if (d || b) {
          if (t.length !== e.length) return !1;

          for (i = 0; i < t.length; i++) if (t[i] !== e[i]) return !1;

          return !0;
        }

        if (typeof t != typeof e) return !1;

        try {
          var m = n(t),
              v = n(e);
        } catch (t) {
          return !1;
        }

        if (m.length !== v.length) return !1;

        for (m.sort(), v.sort(), i = m.length - 1; i >= 0; i--) if (m[i] != v[i]) return !1;

        for (i = m.length - 1; i >= 0; i--) if (h = m[i], !c(t[h], e[h], r)) return !1;

        return !0;
      }(t, e, h));
    }

    function l(t) {
      return null == t;
    }

    function f(t) {
      return !(!t || "object" != typeof t || "number" != typeof t.length) && "function" == typeof t.copy && "function" == typeof t.slice && !(t.length > 0 && "number" != typeof t[0]);
    }

    t.exports = c;
  }, function (t, e, r) {
    "use strict";

    var n = Array.prototype.slice,
        o = r(8),
        i = Object.keys,
        s = i ? function (t) {
      return i(t);
    } : r(19),
        a = Object.keys;
    s.shim = function () {
      Object.keys ? function () {
        var t = Object.keys(arguments);
        return t && t.length === arguments.length;
      }(1, 2) || (Object.keys = function (t) {
        return o(t) ? a(n.call(t)) : a(t);
      }) : Object.keys = s;
      return Object.keys || s;
    }, t.exports = s;
  }, function (t, e, r) {
    "use strict";

    var n = Object.prototype.toString;

    t.exports = function (t) {
      var e = n.call(t),
          r = "[object Arguments]" === e;
      return r || (r = "[object Array]" !== e && null !== t && "object" == typeof t && "number" == typeof t.length && t.length >= 0 && "[object Function]" === n.call(t.callee)), r;
    };
  }, function (t, e, r) {
    "use strict";

    t.exports = function () {
      if ("function" != typeof Symbol || "function" != typeof Object.getOwnPropertySymbols) return !1;
      if ("symbol" == typeof Symbol.iterator) return !0;
      var t = {},
          e = Symbol("test"),
          r = Object(e);
      if ("string" == typeof e) return !1;
      if ("[object Symbol]" !== Object.prototype.toString.call(e)) return !1;
      if ("[object Symbol]" !== Object.prototype.toString.call(r)) return !1;

      for (e in t[e] = 42, t) return !1;

      if ("function" == typeof Object.keys && 0 !== Object.keys(t).length) return !1;
      if ("function" == typeof Object.getOwnPropertyNames && 0 !== Object.getOwnPropertyNames(t).length) return !1;
      var n = Object.getOwnPropertySymbols(t);
      if (1 !== n.length || n[0] !== e) return !1;
      if (!Object.prototype.propertyIsEnumerable.call(t, e)) return !1;

      if ("function" == typeof Object.getOwnPropertyDescriptor) {
        var o = Object.getOwnPropertyDescriptor(t, e);
        if (42 !== o.value || !0 !== o.enumerable) return !1;
      }

      return !0;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(2),
        o = r(4),
        i = o(n("String.prototype.indexOf"));

    t.exports = function (t, e) {
      var r = n(t, !!e);
      return "function" == typeof r && i(t, ".prototype.") > -1 ? o(r) : r;
    };
  }, function (t, e, r) {
    "use strict";

    var n = function (t) {
      return t != t;
    };

    t.exports = function (t, e) {
      return 0 === t && 0 === e ? 1 / t == 1 / e : t === e || !(!n(t) || !n(e));
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(11);

    t.exports = function () {
      return "function" == typeof Object.is ? Object.is : n;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(29).functionsHaveConfigurableNames(),
        o = Object,
        i = TypeError;
    t.exports = function () {
      if (null != (this || _global) && (this || _global) !== o(this || _global)) throw new i("RegExp.prototype.flags getter called on non-object");
      var t = "";
      return (this || _global).hasIndices && (t += "d"), (this || _global).global && (t += "g"), (this || _global).ignoreCase && (t += "i"), (this || _global).multiline && (t += "m"), (this || _global).dotAll && (t += "s"), (this || _global).unicode && (t += "u"), (this || _global).sticky && (t += "y"), t;
    }, n && Object.defineProperty && Object.defineProperty(t.exports, "name", {
      value: "get flags"
    });
  }, function (t, e, r) {
    "use strict";

    var n = r(13),
        o = r(0).supportsDescriptors,
        i = Object.getOwnPropertyDescriptor;

    t.exports = function () {
      if (o && "gim" === /a/gim.flags) {
        var t = i(RegExp.prototype, "flags");

        if (t && "function" == typeof t.get && "boolean" == typeof RegExp.prototype.dotAll && "boolean" == typeof RegExp.prototype.hasIndices) {
          var e = "",
              r = {};
          if (Object.defineProperty(r, "hasIndices", {
            get: function () {
              e += "d";
            }
          }), Object.defineProperty(r, "sticky", {
            get: function () {
              e += "y";
            }
          }), "dy" === e) return t.get;
        }
      }

      return n;
    };
  }, function (t, e, r) {
    "use strict";

    var n = Object.prototype.hasOwnProperty,
        o = Object.prototype.toString,
        i = Object.defineProperty,
        s = Object.getOwnPropertyDescriptor,
        a = function (t) {
      return "function" == typeof Array.isArray ? Array.isArray(t) : "[object Array]" === o.call(t);
    },
        u = function (t) {
      if (!t || "[object Object]" !== o.call(t)) return !1;
      var e,
          r = n.call(t, "constructor"),
          i = t.constructor && t.constructor.prototype && n.call(t.constructor.prototype, "isPrototypeOf");
      if (t.constructor && !r && !i) return !1;

      for (e in t);

      return void 0 === e || n.call(t, e);
    },
        p = function (t, e) {
      i && "__proto__" === e.name ? i(t, e.name, {
        enumerable: !0,
        configurable: !0,
        value: e.newValue,
        writable: !0
      }) : t[e.name] = e.newValue;
    },
        c = function (t, e) {
      if ("__proto__" === e) {
        if (!n.call(t, e)) return;
        if (s) return s(t, e).value;
      }

      return t[e];
    };

    t.exports = function t() {
      var e,
          r,
          n,
          o,
          i,
          s,
          l = arguments[0],
          f = 1,
          h = arguments.length,
          y = !1;

      for ("boolean" == typeof l && (y = l, l = arguments[1] || {}, f = 2), (null == l || "object" != typeof l && "function" != typeof l) && (l = {}); f < h; ++f) if (null != (e = arguments[f])) for (r in e) n = c(l, r), l !== (o = c(e, r)) && (y && o && (u(o) || (i = a(o))) ? (i ? (i = !1, s = n && a(n) ? n : []) : s = n && u(n) ? n : {}, p(l, {
        name: r,
        newValue: t(y, s, o)
      })) : void 0 !== o && p(l, {
        name: r,
        newValue: o
      }));

      return l;
    };
  }, function (t, e, r) {
    "use strict";

    const n = "undefined" == typeof URL ? r(33).URL : URL,
          o = (t, e) => e.some(e => e instanceof RegExp ? e.test(t) : e === t),
          i = (t, e) => {
      if (e = {
        defaultProtocol: "http:",
        normalizeProtocol: !0,
        forceHttp: !1,
        forceHttps: !1,
        stripAuthentication: !0,
        stripHash: !1,
        stripWWW: !0,
        removeQueryParameters: [/^utm_\w+/i],
        removeTrailingSlash: !0,
        removeDirectoryIndex: !1,
        sortQueryParameters: !0,
        ...e
      }, Reflect.has(e, "normalizeHttps")) throw new Error("options.normalizeHttps is renamed to options.forceHttp");
      if (Reflect.has(e, "normalizeHttp")) throw new Error("options.normalizeHttp is renamed to options.forceHttps");
      if (Reflect.has(e, "stripFragment")) throw new Error("options.stripFragment is renamed to options.stripHash");
      if (t = t.trim(), /^data:/i.test(t)) return ((t, {
        stripHash: e
      }) => {
        const r = t.match(/^data:([^,]*?),([^#]*?)(?:#(.*))?$/);
        if (!r) throw new Error("Invalid URL: " + t);
        const n = r[1].split(";"),
              o = r[2],
              i = e ? "" : r[3];
        let s = !1;
        "base64" === n[n.length - 1] && (n.pop(), s = !0);
        const a = (n.shift() || "").toLowerCase(),
              u = [...n.map(t => {
          let [e, r = ""] = t.split("=").map(t => t.trim());
          return "charset" === e && (r = r.toLowerCase(), "us-ascii" === r) ? "" : `${e}${r ? "=" + r : ""}`;
        }).filter(Boolean)];
        return s && u.push("base64"), (0 !== u.length || a && "text/plain" !== a) && u.unshift(a), `data:${u.join(";")},${s ? o.trim() : o}${i ? "#" + i : ""}`;
      })(t, e);
      const r = t.startsWith("//");
      !r && /^\.*\//.test(t) || (t = t.replace(/^(?!(?:\w+:)?\/\/)|^\/\//, e.defaultProtocol));
      const i = new n(t);
      if (e.forceHttp && e.forceHttps) throw new Error("The `forceHttp` and `forceHttps` options cannot be used together");

      if (e.forceHttp && "https:" === i.protocol && (i.protocol = "http:"), e.forceHttps && "http:" === i.protocol && (i.protocol = "https:"), e.stripAuthentication && (i.username = "", i.password = ""), e.stripHash && (i.hash = ""), i.pathname && (i.pathname = i.pathname.replace(/((?!:).|^)\/{2,}/g, (t, e) => /^(?!\/)/g.test(e) ? e + "/" : "/")), i.pathname && (i.pathname = decodeURI(i.pathname)), !0 === e.removeDirectoryIndex && (e.removeDirectoryIndex = [/^index\.[a-z]+$/]), Array.isArray(e.removeDirectoryIndex) && e.removeDirectoryIndex.length > 0) {
        let t = i.pathname.split("/");
        const r = t[t.length - 1];
        o(r, e.removeDirectoryIndex) && (t = t.slice(0, t.length - 1), i.pathname = t.slice(1).join("/") + "/");
      }

      if (i.hostname && (i.hostname = i.hostname.replace(/\.$/, ""), e.stripWWW && /^www\.([a-z\-\d]{2,63})\.([a-z.]{2,5})$/.test(i.hostname) && (i.hostname = i.hostname.replace(/^www\./, ""))), Array.isArray(e.removeQueryParameters)) for (const t of [...i.searchParams.keys()]) o(t, e.removeQueryParameters) && i.searchParams.delete(t);
      return e.sortQueryParameters && i.searchParams.sort(), e.removeTrailingSlash && (i.pathname = i.pathname.replace(/\/$/, "")), t = i.toString(), !e.removeTrailingSlash && "/" !== i.pathname || "" !== i.hash || (t = t.replace(/\/$/, "")), r && !e.normalizeProtocol && (t = t.replace(/^http:\/\//, "//")), e.stripProtocol && (t = t.replace(/^(?:https?:)?\/\//, "")), t;
    };

    t.exports = i, t.exports.default = i;
  }, function (t, e, r) {
    "use strict";

    r.r(e), r.d(e, "default", function () {
      return y;
    });
    var n = r(5),
        o = r.n(n),
        i = r(16),
        s = r.n(i);

    function a(t, e) {
      return function (t) {
        if (Array.isArray(t)) return t;
      }(t) || function (t, e) {
        var r = null == t ? null : "undefined" != typeof Symbol && t[Symbol.iterator] || t["@@iterator"];
        if (null == r) return;
        var n,
            o,
            i = [],
            s = !0,
            a = !1;

        try {
          for (r = r.call(t); !(s = (n = r.next()).done) && (i.push(n.value), !e || i.length !== e); s = !0);
        } catch (t) {
          a = !0, o = t;
        } finally {
          try {
            s || null == r.return || r.return();
          } finally {
            if (a) throw o;
          }
        }

        return i;
      }(t, e) || function (t, e) {
        if (!t) return;
        if ("string" == typeof t) return u(t, e);
        var r = Object.prototype.toString.call(t).slice(8, -1);
        "Object" === r && t.constructor && (r = t.constructor.name);
        if ("Map" === r || "Set" === r) return Array.from(t);
        if ("Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)) return u(t, e);
      }(t, e) || function () {
        throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
      }();
    }

    function u(t, e) {
      (null == e || e > t.length) && (e = t.length);

      for (var r = 0, n = new Array(e); r < e; r++) n[r] = t[r];

      return n;
    }

    function p(t, e) {
      var r = Object.keys(t);

      if (Object.getOwnPropertySymbols) {
        var n = Object.getOwnPropertySymbols(t);
        e && (n = n.filter(function (e) {
          return Object.getOwnPropertyDescriptor(t, e).enumerable;
        })), r.push.apply(r, n);
      }

      return r;
    }

    function c(t) {
      for (var e = 1; e < arguments.length; e++) {
        var r = null != arguments[e] ? arguments[e] : {};
        e % 2 ? p(Object(r), !0).forEach(function (e) {
          l(t, e, r[e]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : p(Object(r)).forEach(function (e) {
          Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
        });
      }

      return t;
    }

    function l(t, e, r) {
      return e in t ? Object.defineProperty(t, e, {
        value: r,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : t[e] = r, t;
    }

    function f(t, e) {
      for (var r = 0; r < e.length; r++) {
        var n = e[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
      }
    }

    var h = {
      globalRegularExpression: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi,
      urlRegularExpression: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi,
      globalMailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/gi,
      mailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/gi,
      normalizeRegularExpression: /(https?:\/\/|www\.)[\S]+/i,
      normalizeUrlOptions: {
        stripWWW: !1
      }
    },
        y = function () {
      function t(e, r) {
        var n = this || _global;
        !function (t, e) {
          if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, t), (this || _global).quill = e, r = r || {}, (this || _global).options = c(c({}, h), r), (this || _global).urlNormalizer = function (t) {
          return n.normalize(t);
        }, (this || _global).mailNormalizer = function (t) {
          return "mailto:".concat(t);
        }, this.registerTypeListener(), this.registerPasteListener(), this.registerBlurListener();
      }

      var e, r, n;
      return e = t, (r = [{
        key: "registerPasteListener",
        value: function () {
          var t = this || _global;
          (this || _global).quill.clipboard.addMatcher("A", function (t, e) {
            var r,
                n = t.getAttribute("href"),
                o = null === (r = e.ops[0]) || void 0 === r ? void 0 : r.attributes;
            return null != (null == o ? void 0 : o.link) && (o.link = n), e;
          }), (this || _global).quill.clipboard.addMatcher(Node.TEXT_NODE, function (e, r) {
            if ("string" == typeof e.data) {
              var n = t.options.globalRegularExpression,
                  i = t.options.globalMailRegularExpression;
              n.lastIndex = 0, i.lastIndex = 0;

              for (var s = new o.a(), a = 0, u = n.exec(e.data), p = i.exec(e.data), c = function (t, r, n) {
                var o = e.data.substring(a, t.index);
                s.insert(o);
                var i = t[0];
                return s.insert(i, {
                  link: n(i)
                }), a = r.lastIndex, r.exec(e.data);
              }; null !== u || null !== p;) if (null === u) p = c(p, i, t.mailNormalizer);else if (null === p) u = c(u, n, t.urlNormalizer);else if (p.index <= u.index) {
                for (; null !== u && u.index < i.lastIndex;) u = n.exec(e.data);

                p = c(p, i, t.mailNormalizer);
              } else {
                for (; null !== p && p.index < n.lastIndex;) p = i.exec(e.data);

                u = c(u, n, t.urlNormalizer);
              }

              if (a > 0) {
                var l = e.data.substring(a);
                s.insert(l), r.ops = s.ops;
              }

              return r;
            }
          });
        }
      }, {
        key: "registerTypeListener",
        value: function () {
          var t = this || _global;

          (this || _global).quill.on("text-change", function (e) {
            var r = e.ops;

            if (!(!r || r.length < 1 || r.length > 2)) {
              var n = r[r.length - 1];
              n.insert && "string" == typeof n.insert && n.insert.match(/\s/) && t.checkTextForUrl(!!n.insert.match(/ |\t/));
            }
          });
        }
      }, {
        key: "registerBlurListener",
        value: function () {
          var t = this || _global;

          (this || _global).quill.root.addEventListener("blur", function () {
            t.checkTextForUrl();
          });
        }
      }, {
        key: "checkTextForUrl",
        value: function () {
          var t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
              e = (this || _global).quill.getSelection();

          if (e) {
            var r = (this || _global).quill.getLeaf(e.index),
                n = a(r, 1),
                o = n[0],
                i = (this || _global).quill.getIndex(o);

            if (o.text) {
              var s = e.index - i,
                  u = o.text.slice(0, s);

              if (u && "a" !== o.parent.domNode.localName) {
                var p = o.text[s];

                if (null == p || !p.match(/\S/)) {
                  var c = t ? /\s\s$/ : /\s$/;

                  if (!u.match(c)) {
                    var l = u.match((this || _global).options.urlRegularExpression),
                        f = u.match((this || _global).options.mailRegularExpression);
                    l ? this.handleMatches(i, u, l, (this || _global).urlNormalizer) : f && this.handleMatches(i, u, f, (this || _global).mailNormalizer);
                  }
                }
              }
            }
          }
        }
      }, {
        key: "handleMatches",
        value: function (t, e, r, n) {
          var o = r.pop(),
              i = e.lastIndexOf(o);
          e.split(o).pop().match(/\S/) || this.updateText(t + i, o.trim(), n);
        }
      }, {
        key: "updateText",
        value: function (t, e, r) {
          var n = new o.a().retain(t).retain(e.length, {
            link: r(e)
          });

          (this || _global).quill.updateContents(n);
        }
      }, {
        key: "normalize",
        value: function (t) {
          if ((this || _global).options.normalizeRegularExpression.test(t)) try {
            return s()(t, (this || _global).options.normalizeUrlOptions);
          } catch (t) {
            console.error(t);
          }
          return t;
        }
      }]) && f(e.prototype, r), n && f(e, n), Object.defineProperty(e, "prototype", {
        writable: !1
      }), t;
    }();

    null != window && window.Quill && window.Quill.register("modules/magicUrl", y);
  }, function (t, e) {
    function r(t, e, s) {
      if (t == e) return t ? [[0, t]] : [];
      (s < 0 || t.length < s) && (s = null);
      var u = o(t, e),
          p = t.substring(0, u);
      u = i(t = t.substring(u), e = e.substring(u));

      var c = t.substring(t.length - u),
          l = function (t, e) {
        var s;
        if (!t) return [[1, e]];
        if (!e) return [[-1, t]];
        var a = t.length > e.length ? t : e,
            u = t.length > e.length ? e : t,
            p = a.indexOf(u);
        if (-1 != p) return s = [[1, a.substring(0, p)], [0, u], [1, a.substring(p + u.length)]], t.length > e.length && (s[0][0] = s[2][0] = -1), s;
        if (1 == u.length) return [[-1, t], [1, e]];

        var c = function (t, e) {
          var r = t.length > e.length ? t : e,
              n = t.length > e.length ? e : t;
          if (r.length < 4 || 2 * n.length < r.length) return null;

          function s(t, e, r) {
            for (var n, s, a, u, p = t.substring(r, r + Math.floor(t.length / 4)), c = -1, l = ""; -1 != (c = e.indexOf(p, c + 1));) {
              var f = o(t.substring(r), e.substring(c)),
                  h = i(t.substring(0, r), e.substring(0, c));
              l.length < h + f && (l = e.substring(c - h, c) + e.substring(c, c + f), n = t.substring(0, r - h), s = t.substring(r + f), a = e.substring(0, c - h), u = e.substring(c + f));
            }

            return 2 * l.length >= t.length ? [n, s, a, u, l] : null;
          }

          var a,
              u,
              p,
              c,
              l,
              f = s(r, n, Math.ceil(r.length / 4)),
              h = s(r, n, Math.ceil(r.length / 2));
          if (!f && !h) return null;
          a = h ? f && f[4].length > h[4].length ? f : h : f;
          t.length > e.length ? (u = a[0], p = a[1], c = a[2], l = a[3]) : (c = a[0], l = a[1], u = a[2], p = a[3]);
          var y = a[4];
          return [u, p, c, l, y];
        }(t, e);

        if (c) {
          var l = c[0],
              f = c[1],
              h = c[2],
              y = c[3],
              g = c[4],
              d = r(l, h),
              b = r(f, y);
          return d.concat([[0, g]], b);
        }

        return function (t, e) {
          for (var r = t.length, o = e.length, i = Math.ceil((r + o) / 2), s = i, a = 2 * i, u = new Array(a), p = new Array(a), c = 0; c < a; c++) u[c] = -1, p[c] = -1;

          u[s + 1] = 0, p[s + 1] = 0;

          for (var l = r - o, f = l % 2 != 0, h = 0, y = 0, g = 0, d = 0, b = 0; b < i; b++) {
            for (var m = -b + h; m <= b - y; m += 2) {
              for (var v = s + m, x = (P = m == -b || m != b && u[v - 1] < u[v + 1] ? u[v + 1] : u[v - 1] + 1) - m; P < r && x < o && t.charAt(P) == e.charAt(x);) P++, x++;

              if (u[v] = P, P > r) y += 2;else if (x > o) h += 2;else if (f) {
                if ((O = s + l - m) >= 0 && O < a && -1 != p[O]) {
                  var w = r - p[O];
                  if (P >= w) return n(t, e, P, x);
                }
              }
            }

            for (var j = -b + g; j <= b - d; j += 2) {
              for (var O = s + j, A = (w = j == -b || j != b && p[O - 1] < p[O + 1] ? p[O + 1] : p[O - 1] + 1) - j; w < r && A < o && t.charAt(r - w - 1) == e.charAt(o - A - 1);) w++, A++;

              if (p[O] = w, w > r) d += 2;else if (A > o) g += 2;else if (!f) {
                if ((v = s + l - j) >= 0 && v < a && -1 != u[v]) {
                  var P = u[v];
                  x = s + P - v;
                  if (P >= (w = r - w)) return n(t, e, P, x);
                }
              }
            }
          }

          return [[-1, t], [1, e]];
        }(t, e);
      }(t = t.substring(0, t.length - u), e = e.substring(0, e.length - u));

      return p && l.unshift([0, p]), c && l.push([0, c]), function t(e) {
        e.push([0, ""]);
        var r,
            n = 0,
            s = 0,
            a = 0,
            u = "",
            p = "";

        for (; n < e.length;) switch (e[n][0]) {
          case 1:
            a++, p += e[n][1], n++;
            break;

          case -1:
            s++, u += e[n][1], n++;
            break;

          case 0:
            s + a > 1 ? (0 !== s && 0 !== a && (0 !== (r = o(p, u)) && (n - s - a > 0 && 0 == e[n - s - a - 1][0] ? e[n - s - a - 1][1] += p.substring(0, r) : (e.splice(0, 0, [0, p.substring(0, r)]), n++), p = p.substring(r), u = u.substring(r)), 0 !== (r = i(p, u)) && (e[n][1] = p.substring(p.length - r) + e[n][1], p = p.substring(0, p.length - r), u = u.substring(0, u.length - r))), 0 === s ? e.splice(n - a, s + a, [1, p]) : 0 === a ? e.splice(n - s, s + a, [-1, u]) : e.splice(n - s - a, s + a, [-1, u], [1, p]), n = n - s - a + (s ? 1 : 0) + (a ? 1 : 0) + 1) : 0 !== n && 0 == e[n - 1][0] ? (e[n - 1][1] += e[n][1], e.splice(n, 1)) : n++, a = 0, s = 0, u = "", p = "";
        }

        "" === e[e.length - 1][1] && e.pop();
        var c = !1;
        n = 1;

        for (; n < e.length - 1;) 0 == e[n - 1][0] && 0 == e[n + 1][0] && (e[n][1].substring(e[n][1].length - e[n - 1][1].length) == e[n - 1][1] ? (e[n][1] = e[n - 1][1] + e[n][1].substring(0, e[n][1].length - e[n - 1][1].length), e[n + 1][1] = e[n - 1][1] + e[n + 1][1], e.splice(n - 1, 1), c = !0) : e[n][1].substring(0, e[n + 1][1].length) == e[n + 1][1] && (e[n - 1][1] += e[n + 1][1], e[n][1] = e[n][1].substring(e[n + 1][1].length) + e[n + 1][1], e.splice(n + 1, 1), c = !0)), n++;

        c && t(e);
      }(l), null != s && (l = function (t, e) {
        var r = function (t, e) {
          if (0 === e) return [0, t];

          for (var r = 0, n = 0; n < t.length; n++) {
            var o = t[n];

            if (-1 === o[0] || 0 === o[0]) {
              var i = r + o[1].length;
              if (e === i) return [n + 1, t];

              if (e < i) {
                t = t.slice();
                var s = e - r,
                    a = [o[0], o[1].slice(0, s)],
                    u = [o[0], o[1].slice(s)];
                return t.splice(n, 1, a, u), [n + 1, t];
              }

              r = i;
            }
          }

          throw new Error("cursor_pos is out of bounds!");
        }(t, e),
            n = r[1],
            o = r[0],
            i = n[o],
            s = n[o + 1];

        if (null == i) return t;
        if (0 !== i[0]) return t;
        if (null != s && i[1] + s[1] === s[1] + i[1]) return n.splice(o, 2, s, i), a(n, o, 2);

        if (null != s && 0 === s[1].indexOf(i[1])) {
          n.splice(o, 2, [s[0], i[1]], [0, i[1]]);
          var u = s[1].slice(i[1].length);
          return u.length > 0 && n.splice(o + 2, 0, [s[0], u]), a(n, o, 3);
        }

        return t;
      }(l, s)), l = function (t) {
        for (var e = !1, r = function (t) {
          return t.charCodeAt(0) >= 56320 && t.charCodeAt(0) <= 57343;
        }, n = 2; n < t.length; n += 1) 0 === t[n - 2][0] && (o = t[n - 2][1]).charCodeAt(o.length - 1) >= 55296 && o.charCodeAt(o.length - 1) <= 56319 && -1 === t[n - 1][0] && r(t[n - 1][1]) && 1 === t[n][0] && r(t[n][1]) && (e = !0, t[n - 1][1] = t[n - 2][1].slice(-1) + t[n - 1][1], t[n][1] = t[n - 2][1].slice(-1) + t[n][1], t[n - 2][1] = t[n - 2][1].slice(0, -1));

        var o;
        if (!e) return t;
        var i = [];

        for (n = 0; n < t.length; n += 1) t[n][1].length > 0 && i.push(t[n]);

        return i;
      }(l);
    }

    function n(t, e, n, o) {
      var i = t.substring(0, n),
          s = e.substring(0, o),
          a = t.substring(n),
          u = e.substring(o),
          p = r(i, s),
          c = r(a, u);
      return p.concat(c);
    }

    function o(t, e) {
      if (!t || !e || t.charAt(0) != e.charAt(0)) return 0;

      for (var r = 0, n = Math.min(t.length, e.length), o = n, i = 0; r < o;) t.substring(i, o) == e.substring(i, o) ? i = r = o : n = o, o = Math.floor((n - r) / 2 + r);

      return o;
    }

    function i(t, e) {
      if (!t || !e || t.charAt(t.length - 1) != e.charAt(e.length - 1)) return 0;

      for (var r = 0, n = Math.min(t.length, e.length), o = n, i = 0; r < o;) t.substring(t.length - o, t.length - i) == e.substring(e.length - o, e.length - i) ? i = r = o : n = o, o = Math.floor((n - r) / 2 + r);

      return o;
    }

    var s = r;

    function a(t, e, r) {
      for (var n = e + r - 1; n >= 0 && n >= e - 1; n--) if (n + 1 < t.length) {
        var o = t[n],
            i = t[n + 1];
        o[0] === i[1] && t.splice(n, 2, [o[0], o[1] + i[1]]);
      }

      return t;
    }

    s.INSERT = 1, s.DELETE = -1, s.EQUAL = 0, t.exports = s;
  }, function (t, e, r) {
    "use strict";

    var n;

    if (!Object.keys) {
      var o = Object.prototype.hasOwnProperty,
          i = Object.prototype.toString,
          s = r(8),
          a = Object.prototype.propertyIsEnumerable,
          u = !a.call({
        toString: null
      }, "toString"),
          p = a.call(function () {}, "prototype"),
          c = ["toString", "toLocaleString", "valueOf", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "constructor"],
          l = function (t) {
        var e = t.constructor;
        return e && e.prototype === t;
      },
          f = {
        $applicationCache: !0,
        $console: !0,
        $external: !0,
        $frame: !0,
        $frameElement: !0,
        $frames: !0,
        $innerHeight: !0,
        $innerWidth: !0,
        $onmozfullscreenchange: !0,
        $onmozfullscreenerror: !0,
        $outerHeight: !0,
        $outerWidth: !0,
        $pageXOffset: !0,
        $pageYOffset: !0,
        $parent: !0,
        $scrollLeft: !0,
        $scrollTop: !0,
        $scrollX: !0,
        $scrollY: !0,
        $self: !0,
        $webkitIndexedDB: !0,
        $webkitStorageInfo: !0,
        $window: !0
      },
          h = function () {
        if ("undefined" == typeof window) return !1;

        for (var t in window) try {
          if (!f["$" + t] && o.call(window, t) && null !== window[t] && "object" == typeof window[t]) try {
            l(window[t]);
          } catch (t) {
            return !0;
          }
        } catch (t) {
          return !0;
        }

        return !1;
      }();

      n = function (t) {
        var e = null !== t && "object" == typeof t,
            r = "[object Function]" === i.call(t),
            n = s(t),
            a = e && "[object String]" === i.call(t),
            f = [];
        if (!e && !r && !n) throw new TypeError("Object.keys called on a non-object");
        var y = p && r;
        if (a && t.length > 0 && !o.call(t, 0)) for (var g = 0; g < t.length; ++g) f.push(String(g));
        if (n && t.length > 0) for (var d = 0; d < t.length; ++d) f.push(String(d));else for (var b in t) y && "prototype" === b || !o.call(t, b) || f.push(String(b));
        if (u) for (var m = function (t) {
          if ("undefined" == typeof window || !h) return l(t);

          try {
            return l(t);
          } catch (t) {
            return !1;
          }
        }(t), v = 0; v < c.length; ++v) m && "constructor" === c[v] || !o.call(t, c[v]) || f.push(c[v]);
        return f;
      };
    }

    t.exports = n;
  }, function (t, e, r) {
    "use strict";

    var n = r(1)(),
        o = r(10)("Object.prototype.toString"),
        i = function (t) {
      return !(n && t && "object" == typeof t && Symbol.toStringTag in t) && "[object Arguments]" === o(t);
    },
        s = function (t) {
      return !!i(t) || null !== t && "object" == typeof t && "number" == typeof t.length && t.length >= 0 && "[object Array]" !== o(t) && "[object Function]" === o(t.callee);
    },
        a = function () {
      return i(arguments);
    }();

    i.isLegacyArguments = s, t.exports = a ? i : s;
  }, function (t, e, r) {
    "use strict";

    var n = "undefined" != typeof Symbol && Symbol,
        o = r(9);

    t.exports = function () {
      return "function" == typeof n && "function" == typeof Symbol && "symbol" == typeof n("foo") && "symbol" == typeof Symbol("bar") && o();
    };
  }, function (t, e, r) {
    "use strict";

    var n = "Function.prototype.bind called on incompatible ",
        o = Array.prototype.slice,
        i = Object.prototype.toString;

    t.exports = function (t) {
      var e = this || _global;
      if ("function" != typeof e || "[object Function]" !== i.call(e)) throw new TypeError(n + e);

      for (var r, s = o.call(arguments, 1), a = function () {
        if ((this || _global) instanceof r) {
          var n = e.apply(this || _global, s.concat(o.call(arguments)));
          return Object(n) === n ? n : this || _global;
        }

        return e.apply(t, s.concat(o.call(arguments)));
      }, u = Math.max(0, e.length - s.length), p = [], c = 0; c < u; c++) p.push("$" + c);

      if (r = Function("binder", "return function (" + p.join(",") + "){ return binder.apply(this,arguments); }")(a), e.prototype) {
        var l = function () {};

        l.prototype = e.prototype, r.prototype = new l(), l.prototype = null;
      }

      return r;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(3);
    t.exports = n.call(Function.call, Object.prototype.hasOwnProperty);
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
        o = r(4),
        i = r(11),
        s = r(12),
        a = r(26),
        u = o(s(), Object);
    n(u, {
      getPolyfill: s,
      implementation: i,
      shim: a
    }), t.exports = u;
  }, function (t, e, r) {
    "use strict";

    var n = r(2)("%Object.defineProperty%", !0),
        o = function () {
      if (n) try {
        return n({}, "a", {
          value: 1
        }), !0;
      } catch (t) {
        return !1;
      }
      return !1;
    };

    o.hasArrayLengthDefineBug = function () {
      if (!o()) return null;

      try {
        return 1 !== n([], "length", {
          value: 1
        }).length;
      } catch (t) {
        return !0;
      }
    }, t.exports = o;
  }, function (t, e, r) {
    "use strict";

    var n = r(12),
        o = r(0);

    t.exports = function () {
      var t = n();
      return o(Object, {
        is: t
      }, {
        is: function () {
          return Object.is !== t;
        }
      }), t;
    };
  }, function (t, e, r) {
    "use strict";

    var n,
        o,
        i,
        s,
        a = r(10),
        u = r(1)();

    if (u) {
      n = a("Object.prototype.hasOwnProperty"), o = a("RegExp.prototype.exec"), i = {};

      var p = function () {
        throw i;
      };

      s = {
        toString: p,
        valueOf: p
      }, "symbol" == typeof Symbol.toPrimitive && (s[Symbol.toPrimitive] = p);
    }

    var c = a("Object.prototype.toString"),
        l = Object.getOwnPropertyDescriptor;
    t.exports = u ? function (t) {
      if (!t || "object" != typeof t) return !1;
      var e = l(t, "lastIndex");
      if (!(e && n(e, "value"))) return !1;

      try {
        o(t, s);
      } catch (t) {
        return t === i;
      }
    } : function (t) {
      return !(!t || "object" != typeof t && "function" != typeof t) && "[object RegExp]" === c(t);
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
        o = r(4),
        i = r(13),
        s = r(14),
        a = r(30),
        u = o(s());
    n(u, {
      getPolyfill: s,
      implementation: i,
      shim: a
    }), t.exports = u;
  }, function (t, e, r) {
    "use strict";

    var n = function () {
      return "string" == typeof function () {}.name;
    },
        o = Object.getOwnPropertyDescriptor;

    if (o) try {
      o([], "length");
    } catch (t) {
      o = null;
    }

    n.functionsHaveConfigurableNames = function () {
      if (!n() || !o) return !1;
      var t = o(function () {}, "name");
      return !!t && !!t.configurable;
    };

    var i = Function.prototype.bind;
    n.boundFunctionsHaveNames = function () {
      return n() && "function" == typeof i && "" !== function () {}.bind().name;
    }, t.exports = n;
  }, function (t, e, r) {
    "use strict";

    var n = r(0).supportsDescriptors,
        o = r(14),
        i = Object.getOwnPropertyDescriptor,
        s = Object.defineProperty,
        a = TypeError,
        u = Object.getPrototypeOf,
        p = /a/;

    t.exports = function () {
      if (!n || !u) throw new a("RegExp.prototype.flags requires a true ES5 environment that supports property descriptors");
      var t = o(),
          e = u(p),
          r = i(e, "flags");
      return r && r.get === t || s(e, "flags", {
        configurable: !0,
        enumerable: !1,
        get: t
      }), t;
    };
  }, function (t, e, r) {
    "use strict";

    var n = Date.prototype.getDay,
        o = Object.prototype.toString,
        i = r(1)();

    t.exports = function (t) {
      return "object" == typeof t && null !== t && (i ? function (t) {
        try {
          return n.call(t), !0;
        } catch (t) {
          return !1;
        }
      }(t) : "[object Date]" === o.call(t));
    };
  }, function (t, e, r) {
    var n = r(6),
        o = r(15),
        i = {
      attributes: {
        compose: function (t, e, r) {
          "object" != typeof t && (t = {}), "object" != typeof e && (e = {});
          var n = o(!0, {}, e);

          for (var i in r || (n = Object.keys(n).reduce(function (t, e) {
            return null != n[e] && (t[e] = n[e]), t;
          }, {})), t) void 0 !== t[i] && void 0 === e[i] && (n[i] = t[i]);

          return Object.keys(n).length > 0 ? n : void 0;
        },
        diff: function (t, e) {
          "object" != typeof t && (t = {}), "object" != typeof e && (e = {});
          var r = Object.keys(t).concat(Object.keys(e)).reduce(function (r, o) {
            return n(t[o], e[o]) || (r[o] = void 0 === e[o] ? null : e[o]), r;
          }, {});
          return Object.keys(r).length > 0 ? r : void 0;
        },
        transform: function (t, e, r) {
          if ("object" != typeof t) return e;

          if ("object" == typeof e) {
            if (!r) return e;
            var n = Object.keys(e).reduce(function (r, n) {
              return void 0 === t[n] && (r[n] = e[n]), r;
            }, {});
            return Object.keys(n).length > 0 ? n : void 0;
          }
        }
      },
      iterator: function (t) {
        return new s(t);
      },
      length: function (t) {
        return "number" == typeof t.delete ? t.delete : "number" == typeof t.retain ? t.retain : "string" == typeof t.insert ? t.insert.length : 1;
      }
    };

    function s(t) {
      (this || _global).ops = t, (this || _global).index = 0, (this || _global).offset = 0;
    }

    s.prototype.hasNext = function () {
      return this.peekLength() < 1 / 0;
    }, s.prototype.next = function (t) {
      t || (t = 1 / 0);
      var e = (this || _global).ops[(this || _global).index];

      if (e) {
        var r = (this || _global).offset,
            n = i.length(e);
        if (t >= n - r ? (t = n - r, (this || _global).index += 1, (this || _global).offset = 0) : (this || _global).offset += t, "number" == typeof e.delete) return {
          delete: t
        };
        var o = {};
        return e.attributes && (o.attributes = e.attributes), "number" == typeof e.retain ? o.retain = t : "string" == typeof e.insert ? o.insert = e.insert.substr(r, t) : o.insert = e.insert, o;
      }

      return {
        retain: 1 / 0
      };
    }, s.prototype.peek = function () {
      return (this || _global).ops[(this || _global).index];
    }, s.prototype.peekLength = function () {
      return (this || _global).ops[(this || _global).index] ? i.length((this || _global).ops[(this || _global).index]) - (this || _global).offset : 1 / 0;
    }, s.prototype.peekType = function () {
      return (this || _global).ops[(this || _global).index] ? "number" == typeof (this || _global).ops[(this || _global).index].delete ? "delete" : "number" == typeof (this || _global).ops[(this || _global).index].retain ? "retain" : "insert" : "retain";
    }, s.prototype.rest = function () {
      if (this.hasNext()) {
        if (0 === (this || _global).offset) return (this || _global).ops.slice((this || _global).index);

        var t = (this || _global).offset,
            e = (this || _global).index,
            r = this.next(),
            n = (this || _global).ops.slice((this || _global).index);

        return (this || _global).offset = t, (this || _global).index = e, [r].concat(n);
      }

      return [];
    }, t.exports = i;
  }, function (t, e, r) {
    "use strict";

    var n = r(34),
        o = r(37);

    function i() {
      (this || _global).protocol = null, (this || _global).slashes = null, (this || _global).auth = null, (this || _global).host = null, (this || _global).port = null, (this || _global).hostname = null, (this || _global).hash = null, (this || _global).search = null, (this || _global).query = null, (this || _global).pathname = null, (this || _global).path = null, (this || _global).href = null;
    }

    e.parse = v, e.resolve = function (t, e) {
      return v(t, !1, !0).resolve(e);
    }, e.resolveObject = function (t, e) {
      return t ? v(t, !1, !0).resolveObject(e) : e;
    }, e.format = function (t) {
      o.isString(t) && (t = v(t));
      return t instanceof i ? t.format() : i.prototype.format.call(t);
    }, e.Url = i;
    var s = /^([a-z0-9.+-]+:)/i,
        a = /:[0-9]*$/,
        u = /^(\/\/?(?!\/)[^\?\s]*)(\?[^\s]*)?$/,
        p = ["{", "}", "|", "\\", "^", "`"].concat(["<", ">", "\"", "`", " ", "\r", "\n", "\t"]),
        c = ["'"].concat(p),
        l = ["%", "/", "?", ";", "#"].concat(c),
        f = ["/", "?", "#"],
        h = /^[+a-z0-9A-Z_-]{0,63}$/,
        y = /^([+a-z0-9A-Z_-]{0,63})(.*)$/,
        g = {
      javascript: !0,
      "javascript:": !0
    },
        d = {
      javascript: !0,
      "javascript:": !0
    },
        b = {
      http: !0,
      https: !0,
      ftp: !0,
      gopher: !0,
      file: !0,
      "http:": !0,
      "https:": !0,
      "ftp:": !0,
      "gopher:": !0,
      "file:": !0
    },
        m = r(38);

    function v(t, e, r) {
      if (t && o.isObject(t) && t instanceof i) return t;
      var n = new i();
      return n.parse(t, e, r), n;
    }

    i.prototype.parse = function (t, e, r) {
      if (!o.isString(t)) throw new TypeError("Parameter 'url' must be a string, not " + typeof t);
      var i = t.indexOf("?"),
          a = -1 !== i && i < t.indexOf("#") ? "?" : "#",
          p = t.split(a);
      p[0] = p[0].replace(/\\/g, "/");
      var v = t = p.join(a);

      if (v = v.trim(), !r && 1 === t.split("#").length) {
        var x = u.exec(v);
        if (x) return (this || _global).path = v, (this || _global).href = v, (this || _global).pathname = x[1], x[2] ? ((this || _global).search = x[2], (this || _global).query = e ? m.parse((this || _global).search.substr(1)) : (this || _global).search.substr(1)) : e && ((this || _global).search = "", (this || _global).query = {}), this || _global;
      }

      var w = s.exec(v);

      if (w) {
        var j = (w = w[0]).toLowerCase();
        (this || _global).protocol = j, v = v.substr(w.length);
      }

      if (r || w || v.match(/^\/\/[^@\/]+@[^@\/]+/)) {
        var O = "//" === v.substr(0, 2);
        !O || w && d[w] || (v = v.substr(2), (this || _global).slashes = !0);
      }

      if (!d[w] && (O || w && !b[w])) {
        for (var A, P, S = -1, E = 0; E < f.length; E++) {
          -1 !== (k = v.indexOf(f[E])) && (-1 === S || k < S) && (S = k);
        }

        -1 !== (P = -1 === S ? v.lastIndexOf("@") : v.lastIndexOf("@", S)) && (A = v.slice(0, P), v = v.slice(P + 1), (this || _global).auth = decodeURIComponent(A)), S = -1;

        for (E = 0; E < l.length; E++) {
          var k;
          -1 !== (k = v.indexOf(l[E])) && (-1 === S || k < S) && (S = k);
        }

        -1 === S && (S = v.length), (this || _global).host = v.slice(0, S), v = v.slice(S), this.parseHost(), (this || _global).hostname = (this || _global).hostname || "";
        var I = "[" === (this || _global).hostname[0] && "]" === (this || _global).hostname[(this || _global).hostname.length - 1];
        if (!I) for (var R = (this || _global).hostname.split(/\./), F = (E = 0, R.length); E < F; E++) {
          var U = R[E];

          if (U && !U.match(h)) {
            for (var N = "", T = 0, C = U.length; T < C; T++) U.charCodeAt(T) > 127 ? N += "x" : N += U[T];

            if (!N.match(h)) {
              var $ = R.slice(0, E),
                  M = R.slice(E + 1),
                  L = U.match(y);
              L && ($.push(L[1]), M.unshift(L[2])), M.length && (v = "/" + M.join(".") + v), (this || _global).hostname = $.join(".");
              break;
            }
          }
        }
        (this || _global).hostname.length > 255 ? (this || _global).hostname = "" : (this || _global).hostname = (this || _global).hostname.toLowerCase(), I || ((this || _global).hostname = n.toASCII((this || _global).hostname));

        var D = (this || _global).port ? ":" + (this || _global).port : "",
            _ = (this || _global).hostname || "";

        (this || _global).host = _ + D, (this || _global).href += (this || _global).host, I && ((this || _global).hostname = (this || _global).hostname.substr(1, (this || _global).hostname.length - 2), "/" !== v[0] && (v = "/" + v));
      }

      if (!g[j]) for (E = 0, F = c.length; E < F; E++) {
        var q = c[E];

        if (-1 !== v.indexOf(q)) {
          var z = encodeURIComponent(q);
          z === q && (z = escape(q)), v = v.split(q).join(z);
        }
      }
      var H = v.indexOf("#");
      -1 !== H && ((this || _global).hash = v.substr(H), v = v.slice(0, H));
      var W = v.indexOf("?");

      if (-1 !== W ? ((this || _global).search = v.substr(W), (this || _global).query = v.substr(W + 1), e && ((this || _global).query = m.parse((this || _global).query)), v = v.slice(0, W)) : e && ((this || _global).search = "", (this || _global).query = {}), v && ((this || _global).pathname = v), b[j] && (this || _global).hostname && !(this || _global).pathname && ((this || _global).pathname = "/"), (this || _global).pathname || (this || _global).search) {
        D = (this || _global).pathname || "";
        var B = (this || _global).search || "";
        (this || _global).path = D + B;
      }

      return (this || _global).href = this.format(), this || _global;
    }, i.prototype.format = function () {
      var t = (this || _global).auth || "";
      t && (t = (t = encodeURIComponent(t)).replace(/%3A/i, ":"), t += "@");
      var e = (this || _global).protocol || "",
          r = (this || _global).pathname || "",
          n = (this || _global).hash || "",
          i = !1,
          s = "";
      (this || _global).host ? i = t + (this || _global).host : (this || _global).hostname && (i = t + (-1 === (this || _global).hostname.indexOf(":") ? (this || _global).hostname : "[" + (this || _global).hostname + "]"), (this || _global).port && (i += ":" + (this || _global).port)), (this || _global).query && o.isObject((this || _global).query) && Object.keys((this || _global).query).length && (s = m.stringify((this || _global).query));
      var a = (this || _global).search || s && "?" + s || "";
      return e && ":" !== e.substr(-1) && (e += ":"), (this || _global).slashes || (!e || b[e]) && !1 !== i ? (i = "//" + (i || ""), r && "/" !== r.charAt(0) && (r = "/" + r)) : i || (i = ""), n && "#" !== n.charAt(0) && (n = "#" + n), a && "?" !== a.charAt(0) && (a = "?" + a), e + i + (r = r.replace(/[?#]/g, function (t) {
        return encodeURIComponent(t);
      })) + (a = a.replace("#", "%23")) + n;
    }, i.prototype.resolve = function (t) {
      return this.resolveObject(v(t, !1, !0)).format();
    }, i.prototype.resolveObject = function (t) {
      if (o.isString(t)) {
        var e = new i();
        e.parse(t, !1, !0), t = e;
      }

      for (var r = new i(), n = Object.keys(this || _global), s = 0; s < n.length; s++) {
        var a = n[s];
        r[a] = (this || _global)[a];
      }

      if (r.hash = t.hash, "" === t.href) return r.href = r.format(), r;

      if (t.slashes && !t.protocol) {
        for (var u = Object.keys(t), p = 0; p < u.length; p++) {
          var c = u[p];
          "protocol" !== c && (r[c] = t[c]);
        }

        return b[r.protocol] && r.hostname && !r.pathname && (r.path = r.pathname = "/"), r.href = r.format(), r;
      }

      if (t.protocol && t.protocol !== r.protocol) {
        if (!b[t.protocol]) {
          for (var l = Object.keys(t), f = 0; f < l.length; f++) {
            var h = l[f];
            r[h] = t[h];
          }

          return r.href = r.format(), r;
        }

        if (r.protocol = t.protocol, t.host || d[t.protocol]) r.pathname = t.pathname;else {
          for (var y = (t.pathname || "").split("/"); y.length && !(t.host = y.shift()););

          t.host || (t.host = ""), t.hostname || (t.hostname = ""), "" !== y[0] && y.unshift(""), y.length < 2 && y.unshift(""), r.pathname = y.join("/");
        }

        if (r.search = t.search, r.query = t.query, r.host = t.host || "", r.auth = t.auth, r.hostname = t.hostname || t.host, r.port = t.port, r.pathname || r.search) {
          var g = r.pathname || "",
              m = r.search || "";
          r.path = g + m;
        }

        return r.slashes = r.slashes || t.slashes, r.href = r.format(), r;
      }

      var v = r.pathname && "/" === r.pathname.charAt(0),
          x = t.host || t.pathname && "/" === t.pathname.charAt(0),
          w = x || v || r.host && t.pathname,
          j = w,
          O = r.pathname && r.pathname.split("/") || [],
          A = (y = t.pathname && t.pathname.split("/") || [], r.protocol && !b[r.protocol]);
      if (A && (r.hostname = "", r.port = null, r.host && ("" === O[0] ? O[0] = r.host : O.unshift(r.host)), r.host = "", t.protocol && (t.hostname = null, t.port = null, t.host && ("" === y[0] ? y[0] = t.host : y.unshift(t.host)), t.host = null), w = w && ("" === y[0] || "" === O[0])), x) r.host = t.host || "" === t.host ? t.host : r.host, r.hostname = t.hostname || "" === t.hostname ? t.hostname : r.hostname, r.search = t.search, r.query = t.query, O = y;else if (y.length) O || (O = []), O.pop(), O = O.concat(y), r.search = t.search, r.query = t.query;else if (!o.isNullOrUndefined(t.search)) {
        if (A) r.hostname = r.host = O.shift(), (I = !!(r.host && r.host.indexOf("@") > 0) && r.host.split("@")) && (r.auth = I.shift(), r.host = r.hostname = I.shift());
        return r.search = t.search, r.query = t.query, o.isNull(r.pathname) && o.isNull(r.search) || (r.path = (r.pathname ? r.pathname : "") + (r.search ? r.search : "")), r.href = r.format(), r;
      }
      if (!O.length) return r.pathname = null, r.search ? r.path = "/" + r.search : r.path = null, r.href = r.format(), r;

      for (var P = O.slice(-1)[0], S = (r.host || t.host || O.length > 1) && ("." === P || ".." === P) || "" === P, E = 0, k = O.length; k >= 0; k--) "." === (P = O[k]) ? O.splice(k, 1) : ".." === P ? (O.splice(k, 1), E++) : E && (O.splice(k, 1), E--);

      if (!w && !j) for (; E--; E) O.unshift("..");
      !w || "" === O[0] || O[0] && "/" === O[0].charAt(0) || O.unshift(""), S && "/" !== O.join("/").substr(-1) && O.push("");
      var I,
          R = "" === O[0] || O[0] && "/" === O[0].charAt(0);
      A && (r.hostname = r.host = R ? "" : O.length ? O.shift() : "", (I = !!(r.host && r.host.indexOf("@") > 0) && r.host.split("@")) && (r.auth = I.shift(), r.host = r.hostname = I.shift()));
      return (w = w || r.host && O.length) && !R && O.unshift(""), O.length ? r.pathname = O.join("/") : (r.pathname = null, r.path = null), o.isNull(r.pathname) && o.isNull(r.search) || (r.path = (r.pathname ? r.pathname : "") + (r.search ? r.search : "")), r.auth = t.auth || r.auth, r.slashes = r.slashes || t.slashes, r.href = r.format(), r;
    }, i.prototype.parseHost = function () {
      var t = (this || _global).host,
          e = a.exec(t);
      e && (":" !== (e = e[0]) && ((this || _global).port = e.substr(1)), t = t.substr(0, t.length - e.length)), t && ((this || _global).hostname = t);
    };
  }, function (t, e, r) {
    (function (t, n) {
      var o;
      /*! https://mths.be/punycode v1.4.1 by @mathias */

      !function (i) {
        e && e.nodeType, t && t.nodeType;
        var s = "object" == typeof n && n;
        s.global !== s && s.window !== s && s.self;
        var a,
            u = 2147483647,
            p = /^xn--/,
            c = /[^\x20-\x7E]/,
            l = /[\x2E\u3002\uFF0E\uFF61]/g,
            f = {
          overflow: "Overflow: input needs wider integers to process",
          "not-basic": "Illegal input >= 0x80 (not a basic code point)",
          "invalid-input": "Invalid input"
        },
            h = Math.floor,
            y = String.fromCharCode;

        function g(t) {
          throw new RangeError(f[t]);
        }

        function d(t, e) {
          for (var r = t.length, n = []; r--;) n[r] = e(t[r]);

          return n;
        }

        function b(t, e) {
          var r = t.split("@"),
              n = "";
          return r.length > 1 && (n = r[0] + "@", t = r[1]), n + d((t = t.replace(l, ".")).split("."), e).join(".");
        }

        function m(t) {
          for (var e, r, n = [], o = 0, i = t.length; o < i;) (e = t.charCodeAt(o++)) >= 55296 && e <= 56319 && o < i ? 56320 == (64512 & (r = t.charCodeAt(o++))) ? n.push(((1023 & e) << 10) + (1023 & r) + 65536) : (n.push(e), o--) : n.push(e);

          return n;
        }

        function v(t) {
          return d(t, function (t) {
            var e = "";
            return t > 65535 && (e += y((t -= 65536) >>> 10 & 1023 | 55296), t = 56320 | 1023 & t), e += y(t);
          }).join("");
        }

        function x(t, e) {
          return t + 22 + 75 * (t < 26) - ((0 != e) << 5);
        }

        function w(t, e, r) {
          var n = 0;

          for (t = r ? h(t / 700) : t >> 1, t += h(t / e); t > 455; n += 36) t = h(t / 35);

          return h(n + 36 * t / (t + 38));
        }

        function j(t) {
          var e,
              r,
              n,
              o,
              i,
              s,
              a,
              p,
              c,
              l,
              f,
              y = [],
              d = t.length,
              b = 0,
              m = 128,
              x = 72;

          for ((r = t.lastIndexOf("-")) < 0 && (r = 0), n = 0; n < r; ++n) t.charCodeAt(n) >= 128 && g("not-basic"), y.push(t.charCodeAt(n));

          for (o = r > 0 ? r + 1 : 0; o < d;) {
            for (i = b, s = 1, a = 36; o >= d && g("invalid-input"), ((p = (f = t.charCodeAt(o++)) - 48 < 10 ? f - 22 : f - 65 < 26 ? f - 65 : f - 97 < 26 ? f - 97 : 36) >= 36 || p > h((u - b) / s)) && g("overflow"), b += p * s, !(p < (c = a <= x ? 1 : a >= x + 26 ? 26 : a - x)); a += 36) s > h(u / (l = 36 - c)) && g("overflow"), s *= l;

            x = w(b - i, e = y.length + 1, 0 == i), h(b / e) > u - m && g("overflow"), m += h(b / e), b %= e, y.splice(b++, 0, m);
          }

          return v(y);
        }

        function O(t) {
          var e,
              r,
              n,
              o,
              i,
              s,
              a,
              p,
              c,
              l,
              f,
              d,
              b,
              v,
              j,
              O = [];

          for (d = (t = m(t)).length, e = 128, r = 0, i = 72, s = 0; s < d; ++s) (f = t[s]) < 128 && O.push(y(f));

          for (n = o = O.length, o && O.push("-"); n < d;) {
            for (a = u, s = 0; s < d; ++s) (f = t[s]) >= e && f < a && (a = f);

            for (a - e > h((u - r) / (b = n + 1)) && g("overflow"), r += (a - e) * b, e = a, s = 0; s < d; ++s) if ((f = t[s]) < e && ++r > u && g("overflow"), f == e) {
              for (p = r, c = 36; !(p < (l = c <= i ? 1 : c >= i + 26 ? 26 : c - i)); c += 36) j = p - l, v = 36 - l, O.push(y(x(l + j % v, 0))), p = h(j / v);

              O.push(y(x(p, 0))), i = w(r, b, n == o), r = 0, ++n;
            }

            ++r, ++e;
          }

          return O.join("");
        }

        a = {
          version: "1.4.1",
          ucs2: {
            decode: m,
            encode: v
          },
          decode: j,
          encode: O,
          toASCII: function (t) {
            return b(t, function (t) {
              return c.test(t) ? "xn--" + O(t) : t;
            });
          },
          toUnicode: function (t) {
            return b(t, function (t) {
              return p.test(t) ? j(t.slice(4).toLowerCase()) : t;
            });
          }
        }, void 0 === (o = function () {
          return a;
        }.call(e, r, e, t)) || (t.exports = o);
      }();
    }).call(this || _global, r(35)(t), r(36));
  }, function (t, e) {
    t.exports = function (t) {
      return t.webpackPolyfill || (t.deprecate = function () {}, t.paths = [], t.children || (t.children = []), Object.defineProperty(t, "loaded", {
        enumerable: !0,
        get: function () {
          return t.l;
        }
      }), Object.defineProperty(t, "id", {
        enumerable: !0,
        get: function () {
          return t.i;
        }
      }), t.webpackPolyfill = 1), t;
    };
  }, function (t, e) {
    var r;

    r = function () {
      return this || _global;
    }();

    try {
      r = r || new Function("return this")();
    } catch (t) {
      "object" == typeof window && (r = window);
    }

    t.exports = r;
  }, function (t, e, r) {
    "use strict";

    t.exports = {
      isString: function (t) {
        return "string" == typeof t;
      },
      isObject: function (t) {
        return "object" == typeof t && null !== t;
      },
      isNull: function (t) {
        return null === t;
      },
      isNullOrUndefined: function (t) {
        return null == t;
      }
    };
  }, function (t, e, r) {
    "use strict";

    e.decode = e.parse = r(39), e.encode = e.stringify = r(40);
  }, function (t, e, r) {
    "use strict";

    function n(t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }

    t.exports = function (t, e, r, i) {
      e = e || "&", r = r || "=";
      var s = {};
      if ("string" != typeof t || 0 === t.length) return s;
      var a = /\+/g;
      t = t.split(e);
      var u = 1000;
      i && "number" == typeof i.maxKeys && (u = i.maxKeys);
      var p = t.length;
      u > 0 && p > u && (p = u);

      for (var c = 0; c < p; ++c) {
        var l,
            f,
            h,
            y,
            g = t[c].replace(a, "%20"),
            d = g.indexOf(r);
        d >= 0 ? (l = g.substr(0, d), f = g.substr(d + 1)) : (l = g, f = ""), h = decodeURIComponent(l), y = decodeURIComponent(f), n(s, h) ? o(s[h]) ? s[h].push(y) : s[h] = [s[h], y] : s[h] = y;
      }

      return s;
    };

    var o = Array.isArray || function (t) {
      return "[object Array]" === Object.prototype.toString.call(t);
    };
  }, function (t, e, r) {
    "use strict";

    var n = function (t) {
      switch (typeof t) {
        case "string":
          return t;

        case "boolean":
          return t ? "true" : "false";

        case "number":
          return isFinite(t) ? t : "";

        default:
          return "";
      }
    };

    t.exports = function (t, e, r, a) {
      return e = e || "&", r = r || "=", null === t && (t = void 0), "object" == typeof t ? i(s(t), function (s) {
        var a = encodeURIComponent(n(s)) + r;
        return o(t[s]) ? i(t[s], function (t) {
          return a + encodeURIComponent(n(t));
        }).join(e) : a + encodeURIComponent(n(t[s]));
      }).join(e) : a ? encodeURIComponent(n(a)) + r + encodeURIComponent(n(t)) : "";
    };

    var o = Array.isArray || function (t) {
      return "[object Array]" === Object.prototype.toString.call(t);
    };

    function i(t, e) {
      if (t.map) return t.map(e);

      for (var r = [], n = 0; n < t.length; n++) r.push(e(t[n], n));

      return r;
    }

    var s = Object.keys || function (t) {
      var e = [];

      for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.push(r);

      return e;
    };
  }]);
});
export default exports;